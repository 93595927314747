import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"
import {
  Container,
  Section,
  Header,
  BackgroundDesktop,
  BackgroundMobile,
} from "./style"

export const Footer = () => {
  return (
    <Container>
      <BackgroundMobile>
        <StaticImage
          src="../../images/mobile-footer-background.png"
          alt="gradient"
          placeholder="blurred"
          objectFit="cover"
          style={{ height: "100%" }}
          quality="100"
        />
      </BackgroundMobile>
      <BackgroundDesktop>
        <StaticImage
          src="../../images/gradient-texture.png"
          alt="gradient"
          placeholder="blurred"
          objectFit="cover"
          style={{ height: "100%" }}
          quality="100"
        />
      </BackgroundDesktop>
      <Section>
        <Header>Quick links</Header>
        <Link to="#technology">Technologies</Link>
        <Link to="/products">Products</Link>
        <Link to="/case-studies">Case Studies</Link>
        <Link to="/blog">Blog</Link>
      </Section>
      <Section>
        <Header>Office Poland</Header>
        <p>Piłsudskiego 43, 50-032 Wrocław, Poland</p>
        <p>office@oakfusion.pl</p>
        <p>+48 797 743 064</p>
      </Section>
      <Section>
        <Header>Austria</Header>
        <p>c/o Impact Hub Vienna Lindengasse 56/18-19 1070 Wien, Austria</p>
        <p>vienna@oakfusion.com</p>
        <p>+43 69919064248</p>
      </Section>
      <Section>
        <Header>Nordic</Header>
        <p>Anckargripsgatan 3 211 19-SE Malmö Sweden</p>
        <p>office@oakfusion.pl</p>
      </Section>
    </Container>
  )
}
